.square{
    width: 100%;
    border-radius: 10%;
    transition: all linear .3s;
  }
  .square:before{
    display: block;
    padding-top: 100%;
    content: "";
  }
  .lightgray{
      color: lightgray;
  }
  .red{
      color: red;
  }
  .life{
    transition: color ease-in .2s;
  }